import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const CountryQuery = gql`
  query countryIcoCodes {
    countryIcoCodes {
      name
      code
    }
  }
`;

const useCountryCodes = () => {
  const { loading, data } = useQuery(CountryQuery);
  const countryCodes: { name: string; code: string }[] = loading
    ? []
    : data.countryIcoCodes;
  const countryCodeMap: { [code: string]: string } = {};
  const countryCodeOptions = countryCodes.map(({ name, code }) => {
    const label = `${name} (${code})`;
    countryCodeMap[code] = label;
    return {
      value: code,
      label,
    };
  });

  return {
    loading,
    countryCodeMap,
    countryCodeOptions,
  };
};

export default useCountryCodes;
